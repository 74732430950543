// PW TOOLS JS FRONT
window.showMessage = showMessage

// INPUT FILE CUSTOM
var inputFileItems = document.querySelectorAll('.input-file')

for (var i = 0; i < inputFileItems.length; i++) {
    var fileInput = inputFileItems[i].children[0],
        button = inputFileItems[i].children[1],
        the_return = inputFileItems[i].children[2]

    button.addEventListener('keydown', function (event) {
        if (event.keyCode == 13 || event.keyCode == 32) {
            this.previousElementSibling.focus()
        }
    })

    button.addEventListener('click', function (event) {
        this.previousElementSibling.focus()
        return false
    })

    fileInput.addEventListener('change', function (event) {
        var this_return = this.nextElementSibling.nextElementSibling
        this_return.innerHTML = this.value.replace('C:\\fakepath\\', ' ')
    })
} // END

// Fonction qui affiche une notification en bas du header pendant 3 secondes
// type : ( string ) class qu'aura l'élément ajouté ( valid, error ou delete )
// message : ( string ) message affiché
function showMessage(type, message) {
    var alert = $('<p>')
    alert.addClass('msg-info flash')
    alert.addClass(type)
    alert.html(message)

    $('body').append(alert)

    setTimeout(function () {
        alert.addClass('open')
    }, 100)

    setTimeout(function () {
        alert.removeClass('open')
    }, 3000)

    setTimeout(function () {
        alert.remove()
    }, 3500)
}

$(document).ready(function () {
    // RETOUR EN HAUT - créer balise #gotop
    $('#gotop').on('click', function () {
        //
        $('html, body').animate({ scrollTop: 0 }, 'slow')
        return false
    })

    // BOUTON TOGGLE MAIN NAV
    $('.toggle-nav').on('click', function () {
        $(this).toggleClass('menu-open');
        $('.sidebar').toggleClass('open');
    });

    // POPUP
    $('[data-popup]').click(function () {
        $('body').addClass('mode-popup')
        $('.toggle-sidebar').removeClass('open')
        $('#' + $(this).attr('data-popup')).addClass('open')
    })

    // CLOSE POPUP
    $('.close-popup').click(function () {
        $('body').removeClass('mode-popup')
        $(this).parent('.popup').removeClass('open')
    })

    // SIDEBAR
    $('[data-toggle-sidebar]').click(function () {
        $('body').addClass('mode-popup')
        $('#' + $(this).attr('data-toggle-sidebar')).addClass('open')
    })

    // CLOSE POPUP
    $('.close-sidebar').click(function () {
        $('body').removeClass('mode-popup')
        $(this).parent('.toggle-sidebar').removeClass('open')
    })

    // ONGLETS
    $('*[data-onglet]').click(function () {
        $('.nav-onglet > a').removeClass('actif')
        $('.onglet-content').removeClass('open')
        $(this).addClass('actif')
        $('#' + $(this).attr('data-onglet')).addClass('open')
    })

    // TOGGLE SECTION
    $('.toggle-item').on('click', '> .toggle-launcher', function () {
        if ($(this).parent().hasClass('open')) {
            $(this).parent().removeClass('open')
        } else {
            $('.toggle-section .toggle-item.open').removeClass('open')
            $(this).parent().addClass('open')
        }

        return false
    })

    // SELECT CUSTOM
    $('.select-custom select').each(function () {
        const dropdown = $('<div />').addClass('dropdown selectDropdown')

        $(this).wrap(dropdown)

        const label = $('<span />').text($(this).attr('placeholder')).insertAfter($(this))
        const list = $('<ul class="list-select" />')

        $(this)
            .find('option')
            .each(function () {
                list.append($('<li />').append($('<a />').text($(this).text())))
            })

        list.insertAfter($(this))

        if ($(this).find('option:selected').length) {
            label.text($(this).find('option:selected').text())
            list.find('li:contains(' + $(this).find('option:selected').text() + ')').addClass('active')
            $(this).parent().addClass('filled')
        }
    })

    $(document).on('click touch', '.selectDropdown ul li a', function (e) {
        e.preventDefault()
        const dropdown = $(this).parent().parent().parent()
        const active = $(this).parent().hasClass('active')
        const label = active ? dropdown.find('select').attr('placeholder') : $(this).text()

        dropdown.find('option').prop('selected', false)
        dropdown.find('ul li').removeClass('active')

        dropdown.toggleClass('filled', !active)
        dropdown.children('span').text(label)

        if (!active) {
            dropdown.find('option:contains(' + $(this).text() + ')').prop('selected', true)
            $(this).parent().addClass('active')
        }

        dropdown.removeClass('open')
    })

    $('.dropdown > span').on('click touch', function (e) {
        const self = $(this).parent()
        self.toggleClass('open')
    })

    $(document).on('click touch', function (e) {
        const dropdown = $('.dropdown')
        if (dropdown !== e.target && !dropdown.has(e.target).length) {
            dropdown.removeClass('open')
        }
    })
}) // FIN DOCUMENT READY
